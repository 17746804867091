import * as React from 'react';
import styles from './navbar.module.scss';
import { ReactComponent as ZirohLogoDark } from '../../../assets/svg/zirohLogoDark.svg';
import { ReactComponent as Ham } from '../../../assets/svg/ham.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/icon_close_black.svg';
import { ReactComponent as ZunuCameraIcon } from '../../../assets/svg/zunu_cam_icon.svg';
import { ReactComponent as ZunuMailIcon } from '../../../assets/svg/zunu_mail_icon_wo_bg.svg';
import { ReactComponent as ZunuMessageIcon } from '../../../assets/svg/zunu_msg_icon.svg';
import { ReactComponent as ZunuDriveIcon } from '../../../assets/svg/zunu_drive_icon_wo_bg.svg';
import { ReactComponent as ZunuPortableIcon } from '../../../assets/svg/zunu_portable_icon.svg';
import { ReactComponent as Arrow } from '../../../assets/svg/arrow-down.svg';
import { Link, Route, useLocation } from 'react-router-dom';
import Button from '../Button/Button.component';
import useNavbarHook from './navbar.hook';
import { DEVICE, PLATFORM, THEME } from '../../constants/constants';
import { useDeviceStore } from '../../../StateManager/DeviceStore';
import { OutsideClickHandler } from '../OutsideClickHandler/OutsideClickHandler';
import useLayout from '../../../Layout/layout.hook';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ROUTES } from '../../constants/routes';
import useAcademicAllianceHook from '../../../Pages/AcademicAlliance/academicAlliance.hook';
import { useAppContext } from '../../../context/appContext';

const variants = {
	open: {
		x: '0vw',
		transition: {
			x: { stiffness: 200 }
		}
	},

	closed: {
		x: '100vw'
	},

	exit: {
		x: '80vw'
	}
};


export default function Navbar() {
	const {
		isSideMenu,
		isProductsDropdown,
		handleHamClick,
		closeSideMenu,
		handleProductsDropdownClose,
		handleProductsClick,
	} = useNavbarHook();

	const { isFooter } = useLayout();
	const { device } = useDeviceStore();

	const location = useLocation();

	const {
	} = useAcademicAllianceHook();

	const { openForm } = useAppContext()

	return (
		<nav className={`${styles.navbar}`}>
			<div className={styles.logoContainer}>
				<Link to={ROUTES.HOME} reloadDocument>
					<ZirohLogoDark className={styles.logo} />
				</Link>
			</div>

			{isFooter && (
				<div className={styles.navOptions}>
					{device == DEVICE.DESKTOP && (
						<div className={styles.navLinks}>
							<OutsideClickHandler
								onOutsideClick={() => {
									handleProductsDropdownClose();
								}}
							>
								<div className={styles.navOption}>
									{/* <p className={`${isProductsDropdown ? `${styles.navOptionActive}` : ''}`}
										onClick={() => { handleProductsClick(); }}>
										Products <Arrow />
									</p> */}
									<div className={`${styles.navDropdown} ${isProductsDropdown ? `${styles.navDropdownActive}` : ''}`}>
										<Link
											reloadDocument
											aria-label="Zunu Camera"
											to={ROUTES.PRODUCTS.CAMERA}
											target="_blank"
										>
											<div className={styles.menuProduct}>
												<div className={styles.productLogo} >
													<ZunuCameraIcon />
													<p>Camera</p>
												</div>
												<p className={styles.productDescription}>
													A Privacy-Preserving Camera
													app that captures and stores
													photos and videos with the
													utmost confidentiality.
												</p>
											</div>
										</Link>
										<Link
											reloadDocument
											aria-label="Zunu Mail"
											to={ROUTES.PRODUCTS.MAIL}
											target="_blank"
										>
											<div className={styles.menuProduct}>
												<div className={styles.productLogo}>
													<ZunuMailIcon />
													<p>Mail</p>
												</div>
												<p className={styles.productDescription}>
													A Privacy-Preserving Email
													System that makes emails
													invisible to email service
													providers and other third
													parties.
												</p>
											</div>
										</Link>
										<Link
											reloadDocument
											aria-label="Zunu Message"
											to={ROUTES.PRODUCTS.MESSAGE}
											target="_blank"
										>
											<div className={styles.menuProduct}>
												<div className={styles.productLogo}>
													<ZunuMessageIcon />
													<p>Message</p>
												</div>
												<p className={styles.productDescription}>
													A Privacy-Preserving
													Messaging app to send and
													receive messages with 100%
													Privacy.
												</p>
											</div>
										</Link>
										<Link
											reloadDocument
											aria-label="Zunu Drive"
											to={ROUTES.PRODUCTS.DRIVE}
											target="_blank"
										>
											<div className={styles.menuProduct}>
												<div className={styles.productLogo}>
													<ZunuDriveIcon />
													<p>Drive</p>
												</div>
												<p className={styles.productDescription}>
													A Privacy-Preserving File
													System that keeps files
													private on Devices and the
													Cloud.
												</p>
											</div>
										</Link>
										<Link
											reloadDocument
											aria-label="Zunu Portable"
											to={ROUTES.PRODUCTS.PORTABLE}
											target="_blank"
										>
											<div className={styles.menuProduct}>
												<div className={styles.productLogo}>
													<ZunuPortableIcon />
													<p>Portable</p>
												</div>
												<p className={styles.productDescription}>
													Privacy-Preserving enclave
													to protect files on USB
													devices.
												</p>
											</div>
										</Link>
									</div>
								</div>
							</OutsideClickHandler>



							<Link
								to={ROUTES.INDIVIDUALS}
								reloadDocument
								className={styles.navOption}
								target="_blank"
							>
								Individuals
							</Link>

							<Link
								to={ROUTES.ENTERPRISE}
								reloadDocument
								className={styles.navOption}
								target="_blank"
							>
								Business
							</Link>

							<Link
								to={ROUTES.SUPPORT}
								reloadDocument
								className={styles.navOption}
								target="_blank"
							>
								Resources & Support
							</Link>
						</div>
					)}

					<div className={styles.flags_and_button_container}>

						<div className={styles.downloadButtonContainer} >
							<Button text="Register Now" id="navDownload" onClick={openForm} />
						</div>


						{device !== DEVICE.DESKTOP && (
							<Ham
								className={styles.ham}
								onClick={handleHamClick}
							/>
						)}
					</div>
				</div>
			)}
			<AnimatePresence>
				{isSideMenu && (
					<motion.div className={styles.sideMenuContainer}>
						<div className={styles.sideMenuWrapper}>
							<OutsideClickHandler
								onOutsideClick={() => {
									closeSideMenu();
								}}
							>
								<motion.div
									variants={variants}
									initial={'closed'}
									animate={isSideMenu ? 'open' : 'closed'}
									exit="exit"
									// style={sideMenuActiveStyle}
									// style={{ touchAction: 'none' }}
									className={isSideMenu ? `${styles.sideMenu} ${styles.sideMenuActive}` : `${styles.sideMenu}`}
								>
									<CloseIcon
										className={styles.menuCloseIcon}
										onClick={closeSideMenu}
									/>
									<div className={styles.menu}>
										{/* <p className={styles.menuHeading}>
											Explore More Products
										</p>

										<div className={styles.menuProductsContainer}>
											{!location.pathname.startsWith(ROUTES.PRODUCTS.CAMERA) && (
												<Link
													reloadDocument
													aria-label="Zunu Camera"
													to={ROUTES.PRODUCTS.CAMERA}
													target="_blank"
												>
													<div className={styles.menuProduct}>
														<div className={styles.productLogo}>
															<ZunuCameraIcon />
															<p>Camera</p>
														</div>
														<p className={styles.productDescription}>
															A Privacy-Preserving
															Camera app that
															captures and stores
															photos and videos
															with the utmost
															confidentiality.
														</p>
													</div>
												</Link>
											)}

											{!location.pathname.startsWith(ROUTES.PRODUCTS.MAIL) && (
												<Link
													reloadDocument
													aria-label="Zunu Mail"
													to={ROUTES.PRODUCTS.MAIL}
													target="_blank"
												>
													<div className={styles.menuProduct}>
														<div className={styles.productLogo}>
															<ZunuMailIcon />
															<p>Mail</p>
														</div>
														<p className={styles.productDescription}>
															A Privacy-Preserving
															Email System that
															makes emails
															invisible to email
															service providers
															and other third
															parties.
														</p>
													</div>
												</Link>
											)}

											{!location.pathname.startsWith(ROUTES.PRODUCTS.MESSAGE) && (
												<Link
													reloadDocument
													aria-label="Zunu Message"
													to={ROUTES.PRODUCTS.MESSAGE}
													target="_blank"
												>
													<div className={styles.menuProduct}>
														<div className={styles.productLogo}>
															<ZunuMessageIcon />
															<p>Message</p>
														</div>
														<p className={styles.productDescription}>
															A Privacy-Preserving
															Messaging app to
															send and receive
															messages with 100%
															Privacy.
														</p>
													</div>
												</Link>
											)}

											{!location.pathname.startsWith(ROUTES.PRODUCTS.DRIVE) && (
												<Link
													reloadDocument
													aria-label="Zunu Drive"
													to={ROUTES.PRODUCTS.DRIVE}
													target="_blank"
												>
													<div className={styles.menuProduct}>
														<div className={styles.productLogo}>
															<ZunuDriveIcon />
															<p>Drive</p>
														</div>
														<p className={styles.productDescription}>
															A Privacy-Preserving
															File System that
															keeps files private
															on Devices and the
															Cloud.
														</p>
													</div>
												</Link>
											)}

											{!location.pathname.startsWith(ROUTES.PRODUCTS.PORTABLE) && (
												<Link
													reloadDocument
													aria-label="Zunu Portable"
													to={ROUTES.PRODUCTS.PORTABLE}
													target="_blank"
												>
													<div className={styles.menuProduct}>
														<div className={styles.productLogo}>
															<ZunuPortableIcon />
															<p>Portable</p>
														</div>
														<p className={styles.productDescription}>
															Privacy-Preserving
															enclave to protect
															files on USB
															devices.
														</p>
													</div>
												</Link>
											)
											}

										</div>

										<hr /> */}


										<Link
											className={styles.menuHeading}
											reloadDocument
											to={ROUTES.INDIVIDUALS}
											target="_blank"
										>
											Individuals
										</Link>

										<hr />

										<Link
											className={styles.menuHeading}
											reloadDocument
											to={ROUTES.ENTERPRISE}
											target="_blank"
										>
											Business
										</Link>
										
										<hr />

										<Link
											className={styles.menuHeading}
											reloadDocument
											to={ROUTES.SUPPORT}
											target="_blank"
										>
											Resources & Support
										</Link>
										<hr />
									</div>
								</motion.div>
							</OutsideClickHandler>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</nav >
	);
}
